/**
 * _icon.scss
 * 
 * Scalable (SVG) icons.
 */



$icon-width:  1em;
$icon-height: 1em;
$icon-sizes:  (2, 3, 4);



.icon {
  display: inline-block;
  vertical-align: middle;

  width: $icon-width;
  height: $icon-height;

  line-height: 1;
}





.icon--print {
display:none;
@include mq(lap){
  display:block;
}
	
	width:auto;
	height:auto;
}

 .icon--print__single {
    
    right: 1rem;
    top: 1rem;
}

.toggle-replies {
  z-index:2;
  top:0;
  height:100%;
  border-bottom:1px solid color(brand,greylt);
  width:1rem;
  padding:0rem 0.25rem;
  color:white;
  font-size:60%;
  &,
  &:link,
  &:active,
  &:focus,
  &:hover {
    text-decoration:none;
    color:white;

    }

  i { display: inline-block;
  vertical-align: middle;}

  &:before {  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
  }

}

// Sizes.
// 
// Creates different sizes based on the map.
@each $size in $icon-sizes {

  .icon--x#{$size} {
    width: $size * $icon-width;
    height: $size * $icon-height;
  }
}



