/**
 * _defaults.scss
 *
 * Defines all global variables and defaults for this theme.
 */


// Colors.
// 
// Define custom colors to use throughout this project as a (nested) map.
// You can define regular colors, or divide them into 'functional' areas.
$colors: (
  brand: (
    greylt: rgba(246, 246, 246, 1),
    greymd:rgba(208, 209, 211, 1),
    greydk:rgba(140, 140, 140, 1),

    greenllogo: rgba(86, 192, 159, 1),
    greenlt: rgba(61, 163, 131, 1),
    greenmd: rgba(49, 130, 104,1),
    yell: rgba(214, 223, 35, 1)
  ), 

  bluez: (
    footer: rgba(13, 64, 106, 1),
    header: rgba(71, 75, 85, 1),
    first: rgba(41, 86, 123, 1),
    second: rgba(48, 119, 176, 1),
    third: rgba(24, 92, 147, 1),
    fourth: rgba(14, 64, 106, 1),
    fifth: rgba(4, 47, 83, 1)
  ),

  brand-alpha:(
    primary: rgba(42, 85, 120, .9),
    secondary: rgba(86, 192, 159, .9),
    teal: rgba(61, 163, 131, .95)
  ),

  basic: (
    wht: rgba(255, 255, 255, 1)
  ),

  basic-alpha: (
    wht: rgba(255, 255, 255, .8),
    wht-border: rgba(255, 255, 255, .5)
  ),

  button: (
    invert: rgba(0, 205, 224, 1)
  ),

  font: (
    base: rgba(71, 75, 85, 1),
    dark: rgba(46, 46, 46, 1),
    primary: rgba(13, 64, 106, 1),
    secondary: rgba(61, 163, 131, 1)
  ),

  ui: (
    border: #ccc,
    required: red,
    background: #fff,
    modal: rgba(11, 58, 96, 1)
  ),

  print: (
    base: #000,
    ui: #999 
  )
);




// Global variables.
// 
// These are used throughout the project. E.g. 'padding' is by default used in
// grids, wrappers, etc.
$padding:   1.5rem;
$font-size: 16px;



// Media queries.
// 
// Define custom media queries that you can call them by a mapped name in the
// media query mixin. Will also be used to generate grids.
// 
// You can also call a custom media query by providing the actual query.
// 
// Example:
// @include mq(lap) { color: red; }
// @include mq('screen and (max-width: 20em)') { color: green; }
$media-queries: (
  //pocket: '',
  //hand:   'screen and (min-width: 33.75em)',  // 540px
  //lap:    'screen and (min-width: 45em)',     // 720px
  //desk:   'screen and (min-width: 67.5em)',   // 1080px
  //wide:   'screen and (min-width: 80em)'      // 1280px

    pocket: '',
  hand:   'screen and (min-width: 20em)',  // 320px
  lap:    'screen and (min-width: 48em)',     // 720px
  desk:   'screen and (min-width: 62em)',   // 1080px
  wide:   'screen and (min-width: 75em)'      // 1280px
);



// Transitions.
// 
// Define custom transitions, so you can call them by a mapped name in the
// transition mixin.
// 
// You can also call a custom transition by providing the actual transition.
// 
// Example:
// @include transition(slow, 'color, background-color');
// @include transition('all 250ms cubic-bezier(.3, .5, .7, .5)');
$transitions: (
  slow:   'all 1s cubic-bezier(.8, 0, .5, 1)',
  normal: 'all .3s cubic-bezier(.8, 0, .5, 1)',
  fast:   'all .1s cubic-bezier(.8, 0, .5, 1)'
);





// Partials.

@import 'partials/functions';
@import 'partials/mixins';



// Vendor files.
@import 'vendor/normalize';
@import 'vendor/boilerplate';
@import 'vendor/boilerplate';



// Base.
@import 'base/document';
@import 'base/typography';
@import 'base/forms';
@import 'base/wordpress';



// Layouts.
@import 'layouts/grid';
@import 'layouts/container';
@import 'layouts/wrapper';
@import 'layouts/faux-table';
@import 'layouts/feature-area';
@import 'layouts/upcoming-area';
@import 'layouts/modals';
@import 'layouts/single-page';
@import 'layouts/blocks';


// Objects.
@import 'objects/btn';
@import 'objects/icon';
@import 'objects/logo';
@import 'objects/nav';
@import 'objects/divs';
@import 'objects/lists';


// Global elements.
@import 'global/header';
@import 'global/footer';



// Components.
// @import 'components/example';



// Print styles.
@import 'base/print';
