/**
 * _header.scss
 * 
 * Styling for the header that appears throughout the website.
 */



// Global header.
.header--global {
  position: relative;




}

  header .logo-container {
      background-color:color(bluez,header);
      background-image: none;
  }


  header .logo {
  background-image: none;
  }

  .menu-profile-container {
  background-image: none !important;
  background-color: color(bluez,header) !important;

  }