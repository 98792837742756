// section
.feature-area {
	padding-top: 1rem;
	height: auto;
	overflow-x: hidden;
min-height:10rem;
	@include mq(lap) {
		padding-top: 0;
	}
}


// feature holder
.feature-area__holder {
	height: 20rem;

	@include mq(lap) {
		height: 26rem;
	}
}


.feature-area--inside {
	.feature-area__holder {
		display: none;

		@include mq(lap) {
			display: block;
		}
	}

	.feature-area__holder__college {
		display: none;

		@include mq(lap) {
			display: block;
		}
	}

	.feature-area__holder--single {
		height: 26rem;

		@include mq(lap) {
			height: 36.25rem;
		}

		&.feature-area__holder--noimage {
			min-height: 4rem;
			height: auto;
		}

		.titlebox {
			padding: 2rem 3rem 2rem .75rem;

	    	@include mq(lap) {
    		    padding: 2rem 2rem 2rem 0;
    		}
		}
	}
}



.feature-area__screen {
	top: 0;
	left: 0;
	z-index: 2;
	height: 10rem;
}

.feature-area--front {
	padding-top: 0;
    padding-bottom: .5rem;

	@include mq(lap) {
		padding-bottom: .5rem;
	}

}

.feature-area--front__backimage {
	background-image:url(img/home_mouse.jpg);
	 top: 0;
	 left: 0;
	display: block;
	opacity: 1;
	visibility: visible;
}


.feature-area--holder__backimage {
	height: 36.25rem;
}

.feature-area--inside__holder {

	@include transition(all 250ms);
	opacity: .0;
	visibility: hidden;
	z-index: 1;

	&#feat1 {
		z-index: 1;
		opacity: 1;
		visibility: visible;
	}

	&.show {
		opacity: 1;
		visibility: visible;
		z-index: 2;
	}
}

.feature-area--inside__noimage {
	padding-top: 0;
}

.feature-area--container {
	padding-bottom: 0;
	padding-left:0;
	padding-right:0;

}

.boxholder {
	z-index: 2;
	padding-left: 0;
	bottom:0;
	@include mq(lap) {
		padding: 0 .75rem;

	}

	&.boxholder--noimage {
		margin-top: 0;
	}

	&.boxholder--image {
		bottom: 0;
		position: absolute;
	}

}


.feature-area--excerpt {
	display: none;

	@include mq(lap) {
	display: block;
	}

}






.titlebox {
	text-align: left;
	padding: 2rem 1.75rem;

	@include mq(lap) {
    padding: 3rem 0;
    padding-left: 2.5rem;
	}
}

.titlebox--home {
	padding-right: 1rem;
}

.linkbox--home {
	background-color: color(brand, greenlt);
	position: relative;

	@include mq(lap) {
		background-color: transparent;
		position: absolute;
		margin-left: 2.25rem;
		margin-top: -1rem;
	}

}

.titlebox--noimage {
	padding: 4rem 0;
}

.titlebox--front {
	width: 100%;
	text-align: left;

	@include mq(lap) {
	width: auto;

	}

	.linkbox {
		position: absolute;
		top: 100%;
		margin-top: -2.5rem;
		left: 1.25rem;

		@include mq(lap) {
			margin-top: -1rem;
			width: auto;
		}


		.btn {
			width: 100%;
		}

		h2 {
		font-size: 1.4rem;

		}

	}

}

.titlebox--carousel {
	@include mq(lap) {
	padding-left: 0;
	}

	p {
		color: color(font, base);
	}

	.linkbox__split {
		width: 50%;
	}

}


.linkbox {
	position: relative;

	@include mq(lap) {
		position: absolute;
		top: 100%;
		left: 0;



	}
}

span.dlicon {
	padding-left: 1rem;
	margin-top: -.75rem;

	i {
		border-radius: 4px;
		font-size: 62%;
		border: solid 1px white;
		display: inline-block;
		padding: .55rem .5rem .45rem;
	}
}

.academic-list {

	span.dlicon {
		padding-left: 0;

		i {
			border: solid 1px color(font, base);
		}
	}
}

.boxholder--single {

	h1 {
		color: color(basic, wht);
	}
}

.feature-area__timer {
	position: absolute;
	height: auto;
	bottom: 6px;
	left: 0;
    z-index: 2;

	@include mq(lap) {
	position: relative;
	top: -.4rem;
	z-index: 2;
	}

	.timer_bar {
		@include transition(width 8s);
		height: .4rem;
		width: 0%;
	}
}

.feature-area--selectboxes {
	height: auto;

	@include mq(lap) {
		height: 13rem;
		margin-top: .4rem;
	}

	.selectbox {
		@include transition(all 250ms);
		cursor: pointer;
		background-size: cover;
		background-position: center;
		text-align: center;
		border-bottom: solid 1px color(basic, wht);

		@include mq (lap) {
		text-align: left;
		border: solid 1px color(basic, wht);
		border-top: 0;
		padding-top: 7rem;

		}

		.selectcontent {
			@include transition(padding 250ms);
			padding: 1rem 1.4rem;
			bottom: 0;
			height: 100%;
		}

		&:hover, &:focus {

			@include mq(lap) {
				padding-top: 5rem;
				cursor: pointer;
			}
		}

		small {
			display: none;
		}

		&.on {
			padding-top: 6rem;
			text-align: center;

			@include mq(lap) {
				padding-top: 0;
			}

			.selectcontent {
				border-bottom: 6px solid white;
				background-color: color(basic-alpha, wht);
				@include mq(hand) {
					p {
					color: color(bluez, blue);
					}
				}
				@include mq(lap) {
					border-bottom: none;
					background-color: color(bluez, blue);
					padding-top: 5rem;

					p {
						color: white;
					}

					small {
					display: none;
					}
				}



				small {
				display: inline-block;
				height: auto;
				width: 100%;

				}

			}

		}

		&#select1 {
			border-left-width: 0;
		}

		&:last-child {
			border-right-width: 0;
		}
	}
}

.reports-bar {
	h2 {

		@include transition(all 250ms);
	}

	span.icon {
	@include transition(all 250ms);
	padding-left: .75rem;


	}

	&:hover {
		cursor: pointer;

		h2 {
			padding-left: 1rem;
		}

		span.icon {
			padding-left: 1rem;
		}

	}
}


