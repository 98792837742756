/**
 * _wrapper.scss
 * 
 * The wrapper object.
 *
 * Used to wrap elements in a constrained area, usually the max-width of the
 * page's content.
 */



.wrapper {
  position: relative;

  max-width: 68rem;

  margin: 0 auto;
  padding: 0 $padding;
}



// Padded wrapper.
.wrapper--padded {
  padding: $padding;
}



// Unpadded wrapper.
.wrapper--unpadded {
  padding: 0;
}



.acf-map {
	width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 20px 0;
}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}
