/**
 * _container.scss
 * 
 * The container object.
 *
 * A flexible container to wrap elements in. By default doesn't have a width,
 * use a wrapper for this instead.
 */

section {

	padding-bottom:4rem;
	min-height:40rem;



}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: $padding;
    padding-left:0.5rem;
  padding-right:0.5rem;
  @include mq(lap){

    padding-left:0rem;
  padding-right:0rem;

  }

}

.container--notoppad {
	padding-top:0;

}

.container--nobotpad {
	padding-bottom:0;

}

// Unpadded container.
.container--unpadded {
  padding: 0;
}


.container--content {
	padding-top:1rem;
	padding-bottom:1.5rem;
	

	&.container--content__tiles {
	padding-top:4rem;
	@include mq(lap){
	padding-top:6rem;
	}
	
	

	@include mq(lap){
		padding-top:4rem;
		//padding-left:2rem;
		//padding-right:2rem;
	}

	@include mq(desk){
		padding-top:4rem;
		//padding-left:8rem;
		//padding-right:8rem;
	}
}


	

}

.container--select-styles {
	padding-top:0.5rem;
	padding-bottom:0.5rem;

}

div.container--content__grid {
padding-left:1.75rem;
padding-right:1.75rem;
	@include mq(lap){
	&:nth-child(2n+0) {
	padding-left:2.5rem;
	padding-right:0.75rem;
	}
	&:nth-child(2n+1){
	padding-right:2.5rem;
	padding-left:0.75rem;

	}
	}

}

.directory-holder {padding-top:4rem;}


.directory--tile__holder {
	padding-bottom:1.25rem;


	@include mq(lap){
	&:nth-child(even), &:nth-child(odd){
	padding-left:0.75rem;
	padding-right:0.75rem;
	}


	&:nth-child(3n+2){
		//padding-left:0.75rem;
		//padding-right:0.25rem;
	}
	&:nth-child(3n+1){
		//padding-left:0.25rem;
		//padding-right:0.75rem;
	}
	&:nth-child(3n+0){
		//padding-left:0.25rem;
		//padding-right:0.25rem;
	}
	}

}

.directory--tile__link {
	display:inline-block;
	
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {cursor:pointer;
  background-color:white;}
  &:hover,
  &:focus{

background-color:color(brand,yellow);
}

}

.directory--tile {
	padding:1rem;
	min-height:14rem;
	display:inline-block;

		.directory--tile__image {

	padding:0.4rem;
		
		
		img {    max-height: 100%;
    		margin-bottom: 1rem;
    		max-width: 100%;
    		}


		}
		.directory--tile__content {


		h2 {margin-bottom:0.5rem;}

		}

		
			&.directory--tile--profile{
			.directory--tile__image {
				img {max-width:100%;}
			}


		}


}

	.directory--tile__college {
		.directory--tile__content--block {
		@include mq(lap){
			min-height:16rem;	
		}
		@include mq(desk){
			min-height:16rem;	
		}


	}		

		}


		.directory--tile__map-image {
			left:0;
			top:0;
			opacity:0.2;
			z-index:1;
			background-image:url(img/map_sm.jpg);
			@include transition(all 250ms);

		}
		a.directory--tile__college {
		h2 {z-index:4;position:relative;}

			
		&,
	    &:link,
	    &:visited {
			padding:4rem 0;
			text-align:center;
			color:white;
			min-height:0rem;
			.directory--tile__map-image {opacity:0.2;}

	     }

	     &:hover,
	      &:focus {
	      text-decoration:none;
	      .directory--tile__map-image {opacity:0.0}

	      }

			


		}

		.directory--tile__staff{
		background-color:white;

		@include mq(lap){
		min-height:41rem;

		}
		@include mq(desk){
		min-height:34rem;

		}
		}

.directory--tile__image {
	margin-bottom:1rem;

}

.directory--tile__member {
padding:0.5rem;



}


.loading-icon  {
	padding-top:7rem;
	h2 {padding-top:1rem;}

}