@charset 'UTF-8';

/*!
 * Theme Name: Soshal
 * Theme URI: http://www.soshal.ca
 * Description: Soshal Custom Theme
 * Version: 0.0.1
 * Author: Soshal (@SoshalGroup)
 * Author URI: http://www.soshal.ca
 * Tags: Soshal, HTML5, CSS3
 */

@import 'defaults';

#wpadminbar {bottom:0;top:initial;}
