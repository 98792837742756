/**
 * _nav.scss
 * 
 * Standardized navigation list.
 *
 * Can be used on any collection of elements, bu <ul> is recommended in most
 * cases.
 */


// remove bootstrap default
.navbar-nav>li>a:hover,
.navbar-nav>li>a:focus {
 background-color: transparent;
}




.navbar {
  padding: .5rem 0;

  @include mq (lap) {
    padding: 0 1rem;
  }

  .container {
    height: 2rem;

    @include mq(lap) {
    height: auto;
    }

  }

  .navbar-toggle {
    border-color: transparent;
    font-size: .9rem;
    margin-top: 0;
    margin-right: 2.75rem;

    .menu {
     overflow: visible;
      color: color(basic, wht);
      clip: auto;
      left: 3rem;
      height: auto;
      top: 0;
      line-height: 2.1rem;
    }

    .icon-bar {
      background-color: color(basic, wht);
    }
  }

}

//wp admin bar behave

.admin-bar .navbar {
  top: 32px;
}

.navbar-header {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2rem;

  @include mq(lap) {
    padding: 0;
    height: auto;
    }

}

.main-nav--holder {
  padding: 0;

  @include mq(lap) {
    padding: 0 .75rem;
  }

}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 2;

  .nav__item {
    font-size: .8em;
    font-weight: 300;

    @include mq(lap) {
      float: right;
    }

    .nav__link {
      padding:10px 10px;
      @include mq(lap){
        padding:10px 15px;
      }
    }

    &:last-child {
      .nav__link {

        @include mq(lap) {
         // padding-right: 0;
        }
      }

  }



  }



}

.nav__fr {
  .nav__item .nav__link {
      padding: 10px 4px;

      @include mq(lap){
        padding: 10px 15px;
      }
    }
  
} 


.nav__link,
.navbar-inverse .navbar-nav .nav__link {
  position: relative;
  display: block;

  &,
  &:link,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
    color: color(basic, wht);
    background-color: transparent;
  }

  &:active,
  &:hover {
    color: color(brand, greenlt);
  }

}



// Sub navigation.
.nav--sub {
  position: relative;

  @include mq(lap) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
}



// Inline navigation.
.nav--inline {

  .nav__item {
    width: auto;
  }

}


.navbar-brand {
  margin: 0;
}



nav {
  @include transition(all 250ms);

  @include mq(hand) {
    padding: 1rem;
  }

  @include mq(lap) {
    padding: 0;
  }

  .container {
    padding: 0;
  }


  .logo-area {
    width: 2rem;
    height: 2rem;
    padding-top: 0;

    @include mq(lap) {
      width: auto;
      padding-top: 3.15rem;
      height: 8rem;

    }
  }

  &.navbar-inverse {
    background-color: color(bluez, header);

    .logo-area {
 
      @include mq(lap) {
        margin-top: 0;
        padding-top: 1.35rem;
        height:6rem;
      }
    }
   
    .unline--semi-w {
      border-bottom: color(basic, wht) 0 solid;
    }

    .unline--d {
    border-bottom: color(brand, greenmd) 0 solid;
    }

  }


  .nav-holder--main {
    padding-top: 5rem;

    @include mq(lap) {
      padding-top: 0;
    }

    .nav__item {
      padding-bottom: 1rem;

      @include mq(lap) {
        padding-bottom: 0;
      }

      .nav__link {

        &::after {
          width: 100%;
          height: 0;
        }

        &:hover {

          &::after {
            width: 100%;
            height: 1px;

            @include mq(lap) {
              height: 4px; 
            }
          }
        }
      }
    }

    .nav--main {
      width: 10rem;
      margin: auto;

      @include mq(lap) {
        width: auto;

      }
    }
  }

  .nav-holder--global {
    padding: .5rem 1rem;
    border: 0;

    @include mq(lap) {
      padding: 0;
    }

    .nav--global {
      padding: 0;

      @include mq(lap) {
      padding: .5rem 0 .25rem 0rem;

      }
    }

    .form--search {
      position: absolute;
      top: 4rem;
      left: 50%;
      margin-left: -5rem;

      @include mq(lap) {
        position: relative;
        margin-left: 0;
        top: 0;
        left: 0;
      }

    }

  }

}


.nav--main {
  margin-bottom: 3rem;

  @include mq(lap) {
    margin-bottom: 0;
  }

  .nav__item {
 
    .nav__link {
      font-size: .9rem;
      text-transform: uppercase;
      padding-left: 0rem;
      padding-right: 0;
      @include mq(lap) {
        margin-left: .65rem;
        margin-right: .65rem;
        margin-bottom: 0;
        padding-top: 1.5rem;
        padding-bottom: 1.75rem;
      }

      @include mq(desk) {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        background-color: transparent;
        color: color(basic, wht);
       }

      &:hover,
      &:focus {
        background-color: transparent;
        color: color(basic, wht);


        &::after {
          height: 100%;
          width: 3px;

          @include mq(lap) {
            width: 100%;
            height: 3px;
          }

        }

      }

      &::after {
        @include transition(all 250ms);
        position: absolute;
        width: 0;
        height: 100%;
        bottom: 0;
        background-color: color(brand, greenlt);
        content: '';
        left: 0;

        @include mq(lap) {
          width: 100%;
          height: 0; 
          bottom: -1px;
        }

      }


    }

  }

  .nav__item--current {

    .nav__link {

      &,
      &:link,
      &:visited,
      &:hover,
      &:focus {

        &::after {
        height: 4px;
        }
      }
    }
  }


  .nav__item {

    &:first-child a {

      @include mq(lap) {
        margin-right: 0;
      }
    }

     &:last-child a {

      @include mq(lap) {
        margin-left: 0;
      }
    }

  }
}

.nav--main--fr {

 .nav__item {
 
    .nav__link {

      @include mq(lap) {
        margin-left: .35rem;
        margin-right: .35rem;

      }

      @include mq(desk) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

    }
  }
}



.mobile--nav__global-holder {
  background-color: color(bluez, header);
}

.nav-holder--main,
.nav-holder--global {
  display: table;
  width: 100%;
  border: 0;
  padding-top: 2rem;
  clear: both;

  @include mq(lap) {
    width: auto;
    margin: auto;
    height: auto;
    display: block;
    padding-top: 0;
  }

}


.nav-holder--global {
  padding: 1rem 0;
  width: 100%;
  border: 1px solid color(basic-alpha, wht-border);
  border-left: 0;
  border-right: 0;

  @include mq(lap) {
    border-top: 0;
    padding: 0;
    width: auto;
    border-bottom: 0 solid color(basic, wht);
    height: auto;
    top: 0;
  }

  .navbar-nav {
    width: auto;

    .nav__item {
      float: left;
    }
  }


  &::before {
    content: '';
    background-color: color(brand, dark);
    left: 100%;
    top: 0;
    height: 1.95rem;
    width: 40%;
    position: absolute;
  }

}

.nav--global {
  background-color: color(brand, dark);
  float: right;

  .nav__item {
    border-bottom: 0;

    .nav__link {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

}

.nav--global--walker {

  .nav__item {

    span {
      line-height:2;
    }

    .nav__link {
      padding-left: 0;
      padding-right: .75rem;
      

    }

    &:last-child {
      .nav__link {
        text-transform: none;
        background-color: color(brand, greenmd);
        border-radius: 1rem;
        font-weight: 400;
        margin-left: .25rem;
        font-size: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 0;
        @include mq(lap){
          font-size: 110%;
        }

        &:hover {
          background-color: color(brand, greenmd);
          color: color(basic, wht);
        }
      }
    }
  }

  &.nav__fr {
  .nav__item {

    &:last-child {
      .nav__link {
        padding-left: 0.25rem;
        padding-right: 0.25rem;

        @include mq(lap){
          font-size: 110%;
          padding-left: 1rem;
          padding-right: 1rem;
        }

      }
    }

  }
  }

}


.shrink,
.navbar-inverse {
  background-color: color(bluez, header);

  button.navbar-toggle {

    .icon-bar {
      background-color: color(brand, greenlt);
    }

  }

}

.shrink {
  box-shadow: 0 19px 80px 0 rgba(0, 0, 0, .52);

  &, 
  &.navbar-inverse {
  padding: .5rem 0;

    @include mq(lap) {
      padding: 0;
    }

    .logo-area {
      margin-top: 0;

      @include mq(lap) {
        width: auto;
        padding-top: 1.6rem;
        height: 4.6rem;
      }

      @include mq(desk) {
        padding-top: 1.55rem;
        height: 4.3rem;
      }

    }

  }


  .nav--main {

    .nav__item {

     .nav__link {
        @include mq(lap) {
          padding-bottom: 1rem;
          padding-top: .5rem;
        }
      }

      &.unline--semi-w {
        border-bottom:0;
     }

    }

  }

}

.nav--global--footer {
  text-align: center;

  .nav__item:first-child {

    .nav__link {
      padding-left: 0;
    }

  }

  .nav__item:last-child {

    .nav__link {
      font-size: 100%;
      padding: 3px 1rem;
      background-color: color(bluez, footer);
      border: 1px solid color(brand, greenlt);
      border-radius: 1rem;

    }


  }

}

.mobile--nav__footer {
  max-width: 22rem;
  margin-left: auto;
  margin-right: auto;

  @include mq(lap) {
    max-width: inherit;
  }

}

.mobile--nav__footer--sub {
  max-width:inherit;
}

.navbar-fixed-top .navbar-collapse {
  @include transition(all 250ms);
  position: fixed;
  text-align: center;
  background-color: color(bluez, footer);
  top: 0;
  right: -100%;
  max-height: 100%;
  min-height: 4rem;
  height: 100%;
  padding: 0;
  width: 95%;
  padding-top: 0;
  border-top: 0 solid transparent;
  overflow-y: scroll;

  @include mq(lap) {
    position: relative;
    padding: 0;
    right: 0;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    min-height: auto;
  }

  &.collapse.in {
  box-shadow: -19px 0 80px 0 rgba(0, 0, 0, .52);
  right: 0;

    @include mq(lap) {
      box-shadow: none;
    }

  }

  .btn--close {
    top: 4.5rem;
    position: absolute;
    display: block;
    z-index: 999;

    @include mq(lap) {
      display: none;
    }
  }


  .nav--main {
    clear: both;

    @include mq(lap) {
      clear: both;
      float: right;
    }
  }

  .nav-holder--global {
  text-align: center;

    .nav--global,
    .form--search {
      display: inline-block;

      @include mq(lap) {
      float: right;
      display: inline-block;
      }

    }

  }


  .navbar-main {
    width: 100%;
    float: right;

    @include mq(lap) {
      width: auto;
    }

  }

}

.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
  margin: 0;
  padding: 0;
}

.navbar-fixed-bottom, .navbar-fixed-top {
  z-index: 3;
  border: 0;
}
