/**
 * _footer.scss
 * 
 * Styling for the footer that appears throughout the website.
 */



// Global footer.
.footer--global {
  overflow-x: hidden;
  padding-bottom: 3rem;
  padding-top: 2rem;
  background-color: color(bluez, footer);

  .container {
    padding: 1rem;
    padding-top: 3.5rem;

    @include mq(lap) {
      padding: 0;
      padding-top: 2.5rem;
    }

    &.mobile--nav__footer {
      padding-bottom: 3rem;
      text-align: center;
      padding-top: 1rem;

      @include mq(lap) {
        text-align: left;
        padding-top: 2rem;
      }
    }

    small {
      font-size: .8em;
    }

    &.container--footer {

      @include mq(lap) {
        padding-bottom: 0;
      }

      .logo-area {
      height: auto;
      margin-top: -.25rem;
      }

    }

    .nav {
      padding-top: 0;

      @include mq(lap) {
        padding-top: 2rem;
      }
    }

    .navbar-brand {
      padding: 0;
      height: 65px;
    }

    .nav--global, 
    .nav--footer {
      padding: 0;
      float: none;
      width: auto;
      display: inline-block;

      @include mq(lap) {
        padding-left: 0rem;
        float: right;
        margin-top: .6rem;
        padding-top: 0;
      }
    }


    .nav--global--footer {
      @include mq(lap) {
        padding-left: 1.25rem;
      }
    }

    .form--search {
      float: none;
      display: inline-block;
      width: auto;

      @include mq(lap) {
       float: right;
      }
    }

    .nav--main {
      float: left;

      @include mq(lap) {
        padding-top: 0;
        float: right;
        li.unline {
          border-left: 0;
          border-bottom: 1px solid color(basic-alpha, wht-border);
        }

        .nav__link {
          padding-top: .75rem;
          margin-bottom: 0;
          padding-bottom: 1.5rem;

          &::after {
            background-color: color(basic, wht);
          }
        }

      }

      li.unline {
        border-left: 1px solid color(basic-alpha, wht-border);
        padding-left: 2rem;
        @include mq(lap) {
          padding-left: 0rem;
          border-left: 0;
          margin-bottom: 1rem;
        }
      }

    }


    .nav--footer {
      padding-top: 0;

      li {
        float:right;
        border-bottom: 0;
        width:100%;
        padding-bottom:0.25rem;

        @include mq(lap) {
          width: auto;
          padding-bottom: 0;
        }
      }

      li>a {
       padding:0 0 0 1rem;
      }

      li:last-child {
  
        a {
          @include mq(lap) {
            padding-right: 0;

          }
        }
      }
    }
  }
}

.legal {
  margin-top:2rem;

  @include mq(lap) {
    margin-top: -2.1rem;
  }
}
.copyright {
  padding-left:1rem;
  text-align:left;
  margin-bottom: 0;
}