/**
 * _btn.scss
 * 
 * Displays an element as a button.
 *
 * Should mainly be used on <button>, <input type="button|submit|reset">, and
 * <a>.
 */


.btn {

  @include transition(all 250ms);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow-x: hidden;
  border: 1px solid transparent;
  border-radius: 0;
  padding: .75rem 1.5rem;
  outline: none;
  font-size: 1em;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.35rem;
  white-space: normal;

  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: none;
  }

  &:hover,
  &:focus {
   cursor: pointer;
  }

  &:active {
    cursor: pointer;

  }

  // Icons in buttons have margin to push them away from the text.
  .icon {
    margin-left: .5em;
  }

  // Reversed icons are on the other side of the text.
  .icon--reversed {
    float: left;
    margin-left: 0;
    margin-right: .5em;
  }
  i {    
    right: 0.5rem;
    position: absolute;
    top: 33%;
    }

}


// SEARCH BAR BUTTONS
.btn-default {

  &,
  &:link, 
  &:visited, 
  &:hover, 
  &:focus, 
  &:active {
    color: color(font, primary);

  }

  &:hover,
  &:focus {
    color: color(font, primary);
    background-color: color(brand, greylt);
    cursor: pointer;
  }

}

.dropdown-toggle {
  font-size: .8rem;


}

// Primary buttons (primary action, like 'submit').
.btn--primary {
  border-color: color(brand, greenlt);
  text-align: center;

  &,
  &:link,
  &:visited {
    color: color(font, primary);
    background-color: transparent;
  }

  &:hover,
  &:focus,
  &.on {
    cursor: pointer;
    color: color(basic, wht);
    background: color(brand, greenlt);
  }

}

.btn--primary--invert {

  &,
  &:link,
  &:visited {
    color:color(basic,wht);
    background: color(brand, greenlt);
    border-width: 0;
  }

  &.btn--toggle {
    opacity: .6;

    &.on,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

}





.btn--secondary {
  
// text-align:left;

  &,
  &:link,
  &:visited {
    border-color:color(bluez,footer);
    color:white;
    padding: 0.25rem 1rem;
    margin: 0.25rem 0;
    background-color: color(bluez,footer);

  }

    &:hover,
    &:focus,
    &.on {
      cursor:pointer;
      color:white;
      background-color: color(brand,greenlt);
          border-color:color(brand,greenlt);

    }

    &:active {
    
    }




  i {    right: 0.5rem;
    position: absolute;
    top: 30%;}

}




.btn--secondary--small {
  border-color: color(font, primary);

  &,
  &:link,
  &:visited {
    color: color(font, primary);
  }

  &:hover,
  &:focus {
    background: color(font, primary);
    color: color(basic, wht);
  }

}


.btn--icon {
  line-height: 1.8;

  &,
  &:link,
  &:visited {
    color: color(brand, greydk);
    padding: .3rem;
    margin: 0;
    background-color: transparent;
  }

  &:hover,
  &:focus {
    color: color(brand, greenlt);
  }

  i {
    font-size: 1.8rem;
  }

}

.btn--close {
  @include transition(color 600ms);

  &,
  &:link,
  &:visited {
    background: none;
    border: 0;
    font-size: 3rem;
    font-weight: 100;
    padding: 0;
    top: 1.5rem;
    right: 1.5rem;
    color: color(brand, greenmd);
    overflow: visible;
 
    @include mq(lap) {
      font-size: 5rem;
    }

  }

  &::after {
    content: 'x';
  }

  &:hover,
  &:focus {
    color: color(basic, wht);
    background-color: transparent;
  }

}

