/**
 * _typography.scss
 * 
 * Contains all base typographic styles.
 *
 * Should not be used for layouts, except margins and padding that tie in with
 * typography directly.
 */
$fonts: 'Open Sans', 'Helvetica', 'Arial', sans-serif;



// Global.
html {
  font-size: 100%;
   @include mq(lap) {
      font-size:10pt;
   }

      @include mq(desk) {
      font-size:12pt;
   }
}



// Document.
body {
  color:   color(font, base);
  font-family: $fonts;
  font-size:   1em;
  font-weight: 400;
  line-height: 1.8;






   

}



// Headings.
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 1rem;

  font-family: $fonts;
  font-weight: 100;
  line-height: 1.8;

  strong {font-weight:800;}
}




h1, .h1 {
  font-weight: 100;
  line-height:1.2;
 font-size:2.2rem;
// margin-bottom:2.6rem;

@include mq(lap){
  font-size:3.2rem;

}
 
}




h2, .h2  {
  font-size: 1.75rem;
    margin-bottom: 2rem;
    line-height: 1.6;


}

h3, .h3 {
  margin-bottom:2rem;
  font-size: 2rem;
  line-height:1.1;

  @include mq(lap){
  font-size:2rem;

}

}

h4, .h4 {
  font-size: 1.8rem;
}

h5, .h5 {
  font-size: 1.65rem;
}

h6, .h6 {
  font-size: 1.3rem;
}

p,h1,h2,h3,h4 {
   // max-width:100%;
      @include mq(lap){  
    //  max-width: 46rem;
      }
      @include mq(desk){
    //  max-width: 28rem;
      }


}


.subheading {
  margin-top: -.5rem;
}



// Paragraphs.
.lead {
  font-size: 1.2rem;
}



// Lists.
ul,
ol {
  margin: 0 0 2rem 0;
  padding: 0 0 0 2rem;
  color:color(brand,greenmd);
  padding-right:1rem;
}




  li {
 line-height: 1.6;

  }



// Blockquotes.
blockquote {

  p:first-child::before,
  p:first-of-type::before {
    content: '\201C';
  }

  p:last-child::after,
  p:last-of-type::after {
    content: '\201D';
  }

}



// Addresses.
address {
  margin-bottom: 1rem;
  
  font-style: normal;
}



// Links.
a {
  cursor:pointer;

     @include transition(all 250ms);


  &,
  &:link,
  &:visited {
    cursor:pointer;

    text-decoration: none;
  }

  &:hover,
  &:focus {
    cursor:pointer;

    text-decoration: underline;

  }

  &:active {
    cursor:pointer;

    text-decoration: underline;
  }

}

.blue {color:color(bluez,footer);}
.wht {color:color(basic,wht);}
.wht-alpha {color:color(basic-alpha,wht);}
.green {color:color(brand,greenlt);}
.yellow {color:color(brand,yellow);}

.dark {color:color(bluez,header);}

.centre, .txt-centre {text-align:center}

