.academic-results {
    list-style-type: none;
    margin: 0;
    
    .academic-list {
        line-height:0;
 
        .btn--blocks {
         text-align:center;
         width:100%;
        }

        .academic-list__holder--header-row {
            background-color:transparent;

            .academic-list__row {
                display: none;
                @include mq(lap) {
                    display: block;
                }
            }
        }


        .academic-list--sublinks__holder {
            height: auto;
            text-align: left;
            width: 100%;
            padding-left: 1.25rem;
            font-size:80%;
            background-color: white;
            margin-top: -2px;
            margin-bottom: 2px;
            
            &,
            p {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            }

            &:hover,
            &:focus {
                background: color(brand, yellow);
            }


        }

    }

    .academic-list__holder {
        @include transition(all 250ms);
        padding: .75rem .5rem;
        height: auto;
        display: inline-block;
        background-color: white;
        width: 100%;
        margin-bottom: 2px;
        list-style-type: none;
        color: color(font, base);

        &:hover,
        &:focus {
            background: color(brand, yellow);
        }

        &.academic-list__holder--header-row {
            &:hover,
            &:focus {
                background: transparent;
            }
        }

        .academic-list__title, 
        .academic-list__row {
            float: left;
            display: inline-block;
        }
    }

    .academic-list__vid {
        position: relative;
        height: auto;
        line-height: 1.6rem;
        text-align: left;
        color: color(font, base);

        iframe {
            width:100%;
            height:20rem;
            }
    }


    .academic-list__link {
        height: auto;
        display: inline-block;
        width: 100%;
    }
}


.paginate_links {
    padding: 0;
    font-size: .75rem;

    .page-numbers,
    .current {
        margin-bottom: .25rem;
        padding: .1rem;
        min-width: 1.5rem;
        width: auto;
        display: inline-block;
        text-align: center;

        &.prev,
        &.next {
         border: 0;

            &:hover,
            &:focus {
                color: color(brand, greenlt);
                background-color: transparent;
            }

        }

        &.prev {
            margin-right: 1rem;
        }

        &.next {
            margin-left: 1rem;
        }

    }

    .current {
        padding: .12rem;
        background-color: color(brand, greenlt);
        color: color(basic, wht);

        @include mq(lap) {
            font-size: 1rem;
            min-width: 2rem;
            padding: .25rem;
            margin-left: .25rem;
            margin-right: .25rem;
        }

    }

    a {

        &,
        &:link,
        &:visited {
           border: solid 1px color(brand, greymd);
            //color: color(font, base);
        }

        &:hover,
        &:focus {
            cursor: pointer;
            background-color: color(brand, greymd);
            text-decoration: none;
        }

    }

}
.pager {text-align:right;}

