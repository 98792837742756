main {

  height:  auto;
  width: 100%;
}


.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.fixed {
  position: fixed;
}

.full-div-width {
  width: 100%;
}

.full-div-height {
  height: 100%;
}

.fl--left {
  float: left;
}

.fl--right {
  float: right;
}

.img--no-rep {
  background-repeat: no-repeat;
}

.img--cover {
  background-size: cover;
}

.img--full {
  background-size: 100%;
}

.img--center {
  background-position: center;
}

.unline {
  border-bottom: color(basic, wht) 1px solid;

  &.nav__item {
    border-bottom-color: transparent;

    @include mq(lap) {
      border-bottom-color: color(basic, wht);
    }
  }

}

.unline_b {
  border-bottom-color: color(bluez, header);
}

.unline--semi-w {
  border-bottom-color: rgba(255, 255, 255, .5);

  &.nav__item {
    border-bottom-color: transparent;

    @include mq(lap) {
      border-bottom-color: rgba(255, 255, 255, .5);
    }
  }
}

.unline--d {
  border-bottom-color: color(brand, dark);
}


.unline--semi-w__start {
  border: 1px solid color(basic-alpha, wht-border);
}


.bkgrey {
  background-color: color(brand, greylt);
}

.bkwhite {
  background-color: color(basic, wht);
}

.bkblue {
  background-color: color(bluez, first);
}

.bkblue1 {
  background-color: color(bluez, second);
}

.bkblue2 {
  background-color: color(bluez, third);

}

.bkblue--alpha {
  background-color: color(brand-alpha, primary);
}


.bkblue--dk {
  background-color: color(bluez, header);
}

.bkteal {
  background-color: color(brand, greenmd);
}

.bkltgreen {
  background-color: color(brand, greenlt);
}

.bkltgreen--alpha {
  background-color: color(brand-alpha, secondary);
}

.bkyellow {
  background-color: color(brand, yell);
}



.bkwhite--alpha {
  background-color: color(basic-alpha, wht);

}

 
.bkgradient {

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#474b5f+0,474b55+100&1+0,0+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#474b5f', endColorstr='#00474b55',GradientType=0 ); /* IE6-9 */



}


.txt-column--2 {
	@include mq(lap){
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
     -webkit-column-gap: 4rem; /* Chrome, Safari, Opera */
    -moz-column-gap: 4rem; /* Firefox */
    column-gap: 4rem;
    }


}

.toggle {

	display:none;
    
&.on {
	display:block;

    }
}

#search-results {
	
min-height:58rem;

}


.after {


  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    left: 100%;
    top: 0;
    display: inline-block;
  }
}

.before {

  &::before {
    content: '';
    position: absolute;
    top: 0;
    background-color:  inherit;
    right:  100%;
    height:  100%;
    width:  200%;
    background-repeat: no-repeat;
    display: inline-block;
  }

}


.beforeimg {
	@include mq(lap) {
		&:before {
			content: "";
		    position: absolute;
		    background-image: inherit;
		    right: 0.75rem;
		    height: 100%;
		    width: 178%;
		    background-size: cover;
		    background-repeat:no-repeat;
		    background-position: left top;

		}

	}



}



.afterimg {
	@include mq(lap) {
		&:after {
			content: "";
		    position: absolute;
		    background-image: inherit;
		    left: 0.75rem;
		    height: 100%;
		    width: 151%;
		    background-size: cover;
		    background-repeat:no-repeat;
		    background-position: right top;

		}
	}


}


.side-nav {
  padding: 0;
  padding-top: 2rem;

  @include mq(lap){
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;

  }
}


hr {margin:12rem 0;}