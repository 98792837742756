


.bucket-links {

	//text-align:center;
	@include mq(lap){
	text-align:left;
	}
	.bucket-links--header {
		margin-bottom:2rem;
	}
	p {font-size:0.85rem;}
	


	.bucket-links--holder {
		padding:0 0.25rem ;
		padding-top:2rem;
		text-align:center;
		padding:0;
		@include mq(lap){
			padding:0 0.25rem ;
			padding-top:2rem;
}


	.bucket-links--image {
	height:15rem;
	background-size:cover;
	
	}



	.bucket-links--content {	
		padding:1rem;
		background-color:color(brand,greylt);
		padding-top:2rem;
		padding-bottom:2rem;
		min-height: 16rem;
		margin-bottom:2rem;
		
		@include mq(lap){
			margin-bottom:0;
		}




	}

	.upcoming__link {
	margin-top:0;
	padding-bottom:2rem;
	background-color:white;

	@include mq(lap){		
		background-color:color(brand,greylt);
	}

	}

	}




}


.upcoming--item__holder {
	
	padding-top:0;

	height:auto;


}




.bucket-links--image--1 {
	background-image:url(img/cp_images/1.jpg);
}

.bucket-links--image--2 {
	background-image:url(img/cp_images/2.jpg);
}

.bucket-links--image--3 {
	background-image:url(img/cp_images/3.jpg);
}

.bucket-links--image--4 {
	background-image:url(img/cp_images/4.jpg);
}

.bucket-links--image--5 {
	background-image:url(img/cp_images/5.jpg);
}

.bucket-links--image--6 {
	background-image:url(img/cp_images/6.jpg);
}

.bucket-links--image--7 {
	background-image:url(img/cp_images/7.jpg);
}

.bucket-links--image--8 {
	background-image:url(img/cp_images/8.jpg);
}

.bucket-links--image--9 {
	background-image:url(img/cp_images/9.jpg);
}

.bucket-links--image--10 {
	background-image:url(img/cp_images/10.jpg);
}

.bucket-links--image--11 {
	background-image:url(img/cp_images/11.jpg);
}

.bucket-links--image--12 {
	background-image:url(img/cp_images/12.jpg);
}

.bucket-links--image--13 {
	background-image:url(img/cp_images/13.jpg);
}


.bucket-links--image--14 {
	background-image:url(img/cp_images/14.jpg);
}

.bucket-links--image--15 {
	background-image:url(img/cp_images/15.jpg);
}

.bucket-links--image--16 {
	background-image:url(img/cp_images/16.jpg);
}

.bucket-links--image--17 {
	background-image:url(img/cp_images/17.jpg);
}

.bucket-links--image--18 {
	background-image:url(img/cp_images/18.jpg);
}

.bucket-links--image--19 {
	background-image:url(img/cp_images/19.jpg);
}

.bucket-links--image--20 {
	background-image:url(img/cp_images/20.jpg);
}

.bucket-links--image--21 {
	background-image:url(img/cp_images/21.jpg);
}

.bucket-links--image--22 {
	background-image:url(img/cp_images/22.jpg);
}

.bucket-links--image--12 {
	background-image:url(img/cp_images/23.jpg);
}

.bucket-links--image--24 {
	background-image:url(img/cp_images/24.jpg);
}

.bucket-links--image--25 {
	background-image:url(img/cp_images/25.jpg);
}

.bucket-links--image--26 {
	background-image:url(img/cp_images/26.jpg);
}

.bucket-links--image--27 {
	background-image:url(img/cp_images/27.jpg);
}

.bucket-links--image--28 {
	background-image:url(img/cp_images/28.jpg);
}

.bucket-links--image--29 {
	background-image:url(img/cp_images/29.jpg);
}

.bucket-links--image--30 {
	background-image:url(img/cp_images/30.jpg);
}

