/**
 * _forms.scss
 * 
 * Default form styling.
 */

//remove bootstrap width
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 100%;
}

// labels.
label {
  display: block;
  cursor: pointer;
  font-weight: 600;
}

// Inputs.
.inputs {
  padding: .75rem 1.5rem;
  border: 1px solid color(bluez, header);
  background-color:color(basic,wht);
  width: 100% ;
  border-radius:0;
  box-shadow: none;
  appearance: none;
}


.inputs[type=search] {
  box-sizing: border-box;
}

.inputs--login {
  background-color:color(brand, greylt);

  &:focus,
  &:active {

   background-color:color(basic,wht);
   }
}

.inputs--nav {
  padding: 0.25rem .5rem;
  width: 100%;

  @include mq(lap) {
    width: 6.5rem;
  }
}



// Helper classes.
.required {
  color: color(ui, required);
}


.query-area__headbar {
	background-color: color(brand, greenlt);
}

.query-area__headbar__title {
	padding: 1rem .75rem .8rem;
}

.query-container {
	min-height: 58rem;
}

.fa-search-input {
  position: absolute;
  right: 15px;
  top: 4px;
 // background: white;
  width: 2rem;
  height: 80%;
  padding-top: 0.5rem;
  display: inline-block;
  color:color(brand,greenlt);
  text-align:center;
}

.fa-search-input, .caret { 
//box-shadow:-4px 0px 3px 2px white;
}





.select-style {

  .btn-group {
    border: 1px solid color(bluez,header);
    padding: 0rem;
    width: 100% ;
    border-radius:0;
    box-shadow: none;
    background-color:white;
    appearance: none;

    &:focus,
    &.open {
      outline: 0 none;

      .dropdown-toggle {
        box-shadow: none;
        background-color:transparent;
      }
    }

    .btn {
      white-space: nowrap;
      margin-bottom: 0;
      line-height: 1.25rem;
      background-color:transparent;
      color:color(brand,dark);
      border-radius:0;

      &.btn-default--active {
        background-color:color(brand, greylt);
        color:color(brand, greenlt);
      }



      .bs-caret {
        .caret {
          top: 0;
          right: 0;
          margin-top: 0;
          width: 2rem;
          height: 100%;
          padding-top: 0.55rem;
          border: 0;

          &::after {  
            content: "\f107";
            font-family: FontAwesome;
            color:color(brand,greenlt);
            font-size:1.5rem;
          }
        }
      }
    }

    .dropdown-menu {
      background-color: color(brand,greymd);

      a:hover {
        background-color: color(brand,greylt);
      }
    }
  }




  

  .fa-search-input {
      position: absolute;
      right: 15px;
      top: 0px;
      width: 2rem;
      height: 80%;
      padding-top: 1rem;
      display: inline-block;
    color:color(brand,greenlt);
    text-align:center;
  }





     &.select-style--feild-holder {
     margin-bottom:0.75rem;
      .btn {
        margin-bottom:0;
        width:100%;
        }
     }

  option {width:100%}



}
