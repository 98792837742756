.basic-block {
	
	p { 
		margin-bottom: 1.5rem;
	}

	h1,h2,p {
	@include mq(lap){
	    max-width: 38rem;
	}
	@include mq(desk){
	    max-width: 38rem;

	}

	}
	h1 {margin-bottom:3rem;

	}
margin-top:0.6rem;
@include mq(lap){
margin-top:0.6rem;
	
}
&.bkblue1 {
margin-top:1.75rem;

	@include mq(lap){
		margin-top:2rem;
	}
	@include mq(desk){
	margin-top:1.25rem;

	}
}

}

.basic-block--center {
	
	text-align:center;
	h1,h2,p {max-width:100%}
}


.basic-block__wrapper--bkgrey {
	
&:nth-child(even) {
		background-color:color(brand,greylt);
}
}

.basic-block__wrapper--bkblue {
	div {
		background-color:inherit;
		}

.unline_semi_w__start {
	border-width:0px;
	border-bottom-width:1px;

	@include mq(lap){
		border-width:0;
		border-right-width:1px;
	}

padding-bottom:3rem;
}
padding:0;
margin-bottom:0.5rem;

.container--content__tiles {
padding-left:0;padding-right:2.5rem;
}
.container--content {
	padding-top:7rem;
	padding-bottom:3rem;
	@include mq(lap){
		padding-right:3rem;

	}
	@include mq(desk){
		padding-right:6rem;
	}
	.grid__item {
		&:nth-child(2n+1){
			padding-left:0;
			@include mq(lap){
				//padding-right:1rem;
			}
			@include mq(desk){
				//padding-right:1.5rem;
			}
		}
		&:nth-child(2n+2){
			padding-left:0;
			@include mq(lap){
				//padding-left:1rem;
			}
			@include mq(desk){
				//padding-left:1.5rem;
			}
		}		
	}
	&.container--content__subs {padding-top:0;}
}

	
&:nth-child(1) {
		background-color:color(bluez,blue2);
}
&:nth-child(2) {
		background-color:color(bluez,blue3);
}

&:nth-child(3) {
		background-color:color(bluez,blue4);
}


.basic-block__helper {


	h2 {margin-bottom:1rem;}
	padding-top:3rem;
	padding-left:1.5rem;
	@include mq(lap){
	padding-left:3rem;
    padding-top: 7.8rem;

	}
	@include mq(desk){
	padding-left: 4rem;
	}
	top:0;

    &:before {
    	content:'';

    	position:absolute;
	    width: 2rem;
	    height: 2rem;
		border-top: 1px solid color( basic-alpha,wht-border);
		border-right: 1px solid color( basic-alpha,wht-border);
		transform: rotate(135deg);
    	background: inherit;
    	left: 2rem;
    	top: -1rem;
	  
		@include mq(lap){
		  transform: rotate(45deg);
	    background: inherit;
	    left: -1rem;
		 left:-1rem;
    	top:8.2rem;		
		border-top: 1px solid color( basic-alpha,wht-border);
		border-right: 1px solid color( basic-alpha,wht-border);

		}


    }

    }

}


.basic-block--home {

	.basic-block__holder {padding-left:0;}


	.container--content {padding-left:2.5rem;padding-right:2.5rem;padding-top:3rem;

	h3 {
	font-size:2rem;
	margin-bottom:2rem;
	strong {font-size:2.6rem;}

	}
	}


}


.basic-block__holder{



@include mq(lap){
//padding-left:2rem;
}




&:nth-child(odd) {
	//	background-color:color(brand,greylt);


}

}

.basic-block__holder--imagediv {
	
	@include mq(lap){
		padding-top:0;
		padding-bottom:0;

	}

}

.basic-block__image {
	height:20rem;
	padding:0rem 0 0;
	@include mq(lap){
		padding:0.75rem 0;
height:100%;
	position:absolute;
	top:0;

	}

}

.basic-block__lpad {

	@include mq(lap){
	padding:0 0 0rem 0.75rem;
	}
}
.basic-block__rpad {
@include mq(lap){
	padding:0 0.75rem 0 0rem;
	}


}

.basic-block__home {
min-height:40rem;
padding:2rem 1.75rem;
	padding-bottom:0rem;

	@include mq(lap){

		padding:2rem;
		padding-top:4rem;
		padding-bottom:4rem;

	}
	@include mq(desk){
		padding-left:4rem;
		padding-top:4rem;
		padding-bottom:4rem;
		padding-right:0.25rem;

	}
}

.basic-block__introtier {
	padding-right:3rem;
	padding-top:4rem;

	padding-bottom:4rem;
	@include mq(lap){
		padding-top:4rem;
		padding-bottom:4rem;

	}
	@include mq(desk){
		padding-right:4rem;
		padding-top:4rem;
		padding-bottom:4rem;

	}

}

.basic-block__tierdiv {
	padding-top:0rem;
	padding-bottom:0;


}



.basic-block__content {
	




}

.basic-block--divider {

margin:0.5rem 0;
	.divider {height:100%;}
	 .divider--short {width:5%;}
	 .divider--long {width:95%;}
	 .divider--full {width:100%;}
	 .divider--none {display:none;}
	 .divider--border {border-left:0.75rem solid color(basic,wht);}
	
}

.basic-block--divider--small {height:0.75rem;}
.basic-block--divider--large {height:2.75rem;}

.basic-block--intro {

	
	h1.basic-block--headers {
	line-height:1.2;
	strong {
	font-size:3rem;

	@include mq(lap){
	font-size:4rem;
	}

	}

	}

	padding-top:4rem;

	.container--content__tiles {padding-top:0;padding-bottom:4rem;



	}

}



