
.singlebox-holder {   
	padding-top:4rem;
	@include mq(lap){
		padding-top:0;
 		display: table;
 	}

 }
.singlebox {
	@include mq(lap){
    	display: table-cell;
    }
	

	&.singlebox--left {
	padding-bottom:3rem;
	@include mq(lap){
	padding-bottom:0rem;
	}	


	}
	&.singlebox--right {
	position:relative;
		@include mq(lap){
			//position:absolute;
			


		}

	}
	.info-box {
		@include mq(lap){
			padding-top:6rem;
			padding-bottom:6rem;
		}

	}
	.info-box__left {
		@include mq(lap){
			padding-right:4.5rem;
		}
		@include mq(desk){
			padding-right:6.5rem;

		}
		h3 {margin-bottom:1.5rem;}
		span {
		padding-right:0.5rem;
			&.indenter { 
				min-width:6rem;
			}
		}
		&.info-list, .info-list {
			padding-top:1rem;
			padding-bottom:1rem;
			
			.select-style {padding-bottom:0;}
			clear:both;
			&.info-list--box {

			.bootstrap-select {
				width:auto !important;
				margin-top:-0.55rem

				}


				//display:inline-block;
				border-bottom:1px solid color(brand,blue);
				
				p.fl--left {
					width:72%;
					@include mq(lap){
					width:72%;
					}
					@include mq(desk){
					width:82%;
					}
				}
				p.fl--right {
					width:18%;
					text-align:center;

					@include mq(lap){
					width:28%;
					}
					@include mq(desk){
					width:18%;
					}
					
				}
				p.block {
					padding:0.5rem 0rem;
				}
				p.block, p:first-child { 
				margin-bottom:0; 
				// font-weight: 600;
				 }
				p:first-child {padding-top:0.5rem;padding-bottom:0.5rem;}
			}
		}

	}

	.info-box__right {
			padding:4rem 0;
		@include mq(lap){
			padding-left:2rem;
		}
		@include mq(desk){
			padding-left:4rem;
		}
		h2 {margin-bottom:1rem;}

	
		
		h1 {
		font-weight:normal;
		//margin-top:-2.3rem;
		}



		.college-logo {

			height:auto;
			background-size:100%;
			background-position:left center;
			background-repeat:no-repeat;
				img {width:100%;}
			
				@include mq(lap){
				height:auto;
				img {width:100%;}

			}




			&.college-logo--btn {
			padding-top:0;
			padding-right: 0rem;
			@include mq(desk){
				//padding-top:1.7rem;
			}



			}

			}	
	}

	.info-box__content-block {padding-top:3rem;}

	.collegepicker {
		p {

			a {
				border-bottom:1px solid (color(ui,modal));
				padding:1.5rem 0;
				display:inline-block;
				&:link,
				&:active,
				&:visited {
					color:color(brand,dark);
				}
				&:hover {
					color:color(brand,greenmd);
					text-decoration:none;
					border-bottom:1px solid (color(brand,greenmd));
				}

				}
			}
		}

		.collegeDrop {
			padding-top: 1.2rem;
			height:4.5rem;
			margin-left: -1.5rem;
    		padding-left: 0.75rem;
			@include mq(lap){
	    	padding-bottom: 1.2rem;
	    	padding-left: 2rem;
				margin-left:-0.75rem;
			}
			@include mq(desk){
				padding-left: 4rem;

			}



		}

	}

		.info-box__back.after {
		&:after {

			left:-0.75rem;
			@include mq(lap){
			left:100%;
			}
		}


	}