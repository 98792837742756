/**
 * _logo.scss
 * 
 * Displays a(n) (SVG) logo.
 *
 * Width and heigth should be defined to avoid scaling issues.
 */



$ratio: .25;
$width: 250px;

.logo-area {

  height: 5.5rem;
  display: inline-block;
  margin-top: 0;
  overflow: hidden;

}



.logo {
  display: inline-block;
  vertical-align: middle;
}

.logo__img {
  display: block;
  width: 210px;
  height:53.5px;

  @include mq(lap) {
    height: $width * $ratio;

    width: $width / 1.12;
  }

  @include mq(desk) {
    width: $width;
  }

}

.logo__gov {

  margin-top: -0.25rem;
  margin-left: 0.5rem;
}

.logo__img--en {
  background-image: url('img/logo.png');
}

.logo__img--fr {
  background-image: url('img/logo_fr.png');
}

.logo__img--enw {
  background-image: url('img/logo__enw.png');
}

.logo__img--frw {
  background-image: url('img/logo__frw.png');
}


.logo--white {
  fill:color(basic,wht);
}

.logo--green {
  fill:color(brand,greenllogo);
}
