.modal-backdrop {
	
background-color: color(ui, modal);

opacity:0;
display:inline-block !important;
top:0;
left:0;
z-index:4;
 @include transition(normal, 'all');
 visibility:hidden;

}




.modal {
	opacity:0.0;
	visibility:hidden;
	background-color: transparent;
    min-height: 3em;
    height: auto;
    top: 0em;    
    padding:0em;
	
	z-index:5;
	display:inline-block !important;
	 @include transition(normal, 'all');


	 .modal--holder {
	 	
		
	 @include mq(lap){
	 	
	 }

	 @include mq(desk){
	 	
	 }

	 h1 {
	 text-align:center;
	 line-height:1.2;
	 strong {width:100%;display:inline-block;}

	 @include mq(lap){
	 	 line-height:1;
	 text-align:left;
	 strong {width:auto;}
	 }

	 }

	 }


	 form {padding-top:2rem;}

	h1 {
		color:white;
		font-weight:normal;
		strong {
			color:color(brand,greenmd);
		}
	}

	h2,p {color:color(brand,greenmd)}

	
	.logo__img {
	    margin-top: 0.5rem;
    	margin-left: 1rem;
		@include mq(lap){
  	    	margin-top: 1.5rem;
    		margin-left: 0.5rem;
    	}


	}


	.modal__footer {

	    display: inline-block;
    	padding-top: 3rem;

    	.box {

    	padding:1rem 0 0 0;
    	&:first-child {padding-left:0.75rem;}
    	&:last-child {padding-right:0.75rem;}
h3 {font-size: 1.25rem;}
    	@include mq(lap){
    	h3 {font-size: 2rem;}
    	padding:1rem 0.75rem 0;
    	}
    		a {
	    		&,
	      		&:link,
	      		&:visited,
	      		&:hover,
	      		&:focus 
	    		{   
	    			width: 100%;
				    text-align: center;
				    padding: 1.5rem 0;
				    border: solid 1px color(brand,greenmd);
				    color: color(brand,greenmd);
				    background-color:color(bluez,footer);
				}

				&:hover,
				&:active {
					background-color:color(ui,modal);

				}
			}

    	}
    		

	}



}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
//overflow:visible;
}

.modal.in {opacity:1.0;visibility:visible}
.modal-backdrop.in {opacity:0.98;visibility:visible}